html{
  background: #f1f1f1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* primary/main color */
/* lighter shades of primary color */
/* darkest grey - used for headings */
/* grey used for paragraphs */
.login {
  margin-bottom: 2rem;
}

.login__logo {
  color: #000;
  font-size: 5.5rem;
  font-weight: 500;
  text-align: center;
  width: 95%;
  margin: 4rem auto;
}

.login__logo__yellow {
  color: #FFC45B;
}

.login__center {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: auto;
  margin: 0 auto;
  padding: 2rem;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 2px 15px 2px rgba(180, 187, 199, 0.8);
  box-shadow: 0px 2px 15px 2px rgba(180, 187, 199, 0.8);
  border-radius: 20px;
}

.login__center__title {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  padding-bottom: 30px;
}

.login__center__close-btn-container {
  position: absolute;
  top: 15px;
  right: 15px;
}

.login__center__form {
  display: -ms-grid;
  display: grid;
  row-gap: 20px;
}

.login__center__login-btn {
  width: 360px;
  height: 50px;
  margin: 10px auto 0;
}

/*# sourceMappingURL=login.css.map */

